<template>
  <div class="success-tip">
    <div class="success-tip-logo">
      <img :src="logoImage" alt="">
    </div>
    <div class="success-tip-title">
      <span>{{ $t('title.visitSuccess') }}</span>
    </div>
    <div class="success-tip-text">
      <span v-if="visitTime">{{ $t('title.visitTime') }}: {{ visitTime }}</span>
      <span v-if="locationName">{{ $t('label.visitLocation') }}: {{ locationName }}</span>
    </div>
    <div class="success-tip-image"></div>
    <div class="success-tip-background">
      <img :src="imgBackground" width="100%" height="100%" alt="">
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    visitTime: {
      type: String,
      required: true
    },
    locationName: {
      type: String,
      required: true
    },
    logoImage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      imgBackground: require("./image/background.png"),
    }
  },
  mounted () {},
};
</script>

<style lang="scss">
.success-tip {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &::-webkit-scrollbar {display: none;}
  .success-tip-logo{
    // padding: 70px 110px 0;
    padding-top: 51px;
    padding-bottom: 44px;
    // height: 32px;
    text-align: center;
    // width: 100%;
    // height: 100%;
    // background: url(./image/logo.png) no-repeat center bottom;
    background-size: 154px;
    &>img{
      height: 32px;
      // width: 100%;
    }
  }
  .success-tip-title{
    // padding: 0 8px 0 9px;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    color: #2468F2;
  }
  .success-tip-text{
    padding-top: 28px;
    text-align: center;
    // padding: 28px 28px 0 28px;
    font-size: 16px;
    font-weight: 400;
    span:first-child{
      display: block;
      margin-bottom: 4px;
    }
  }
  .success-tip-image{
    margin-top: 30px;
    background: url(./image/image.png) no-repeat center;
    background-size: auto 100%;
    // padding: 178px;
    width: 100%;
    height: 356px;
  }
  .success-tip-background{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 375px;
    height: 231px;
    z-index: -1;
  }
}
</style>
