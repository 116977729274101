<template>
  <div class="invite-view">
    <template v-if="showNotice">
      <div class="notice-box">
        <img src="./components/image/upload.png"
             alt="">
        <span>{{$t('noticeTitle.desc')}}</span>
        <span>{{$t('noticeTitle.desc1')}}{{noticeTime}}{{$t('noticeTitle.desc2')}}</span>
      </div>
    </template>
    <template v-else-if="applySuccess">
      <success-tip :visit-time="formData.estimatedVisitTimeText"
                   :location-name="formData.mettingDepartment"
                   :logoImage="companyLogo != ''?companyLogo : imgLogo" />
    </template>
    <template v-else-if="formData.type === 5 && viewSwitch">
      <div class="invite-view-form supplement"
           :class="{'invite-view-form-zh': locale === 'zh-CN' || locale === 'zh-cn'}">
        <iot-form v-if="formVisibleLocal"
                  :form-label-position="'top'"
                  :form-items="localItems"
                  :form-rules="localRules"
                  :form-data="localData"
                  :btn-opera="btnOpera2"
                  @btn-click="btnEvent">
          <template slot="return">
            <div class="incite-view-form-return-btn">
            </div>
          </template>
          <template slot="logo">
            <div class="incite-view-form-iogo">
              <img :src="companyLogo != ''?companyLogo : imgLogo"
                   alt="" />
              <div class="language"
                   :class="langClick?'show':''"
                   @click="langClick = !langClick">
                <img style="width:20px;"
                     :src="languageImg"
                     alt="" />
                <span>{{language}}</span>
                <i class="van-icon van-icon-arrow " />
                <ul class="selete">
                  <li @click="changeLang('zh-CN')">中文</li>
                  <li @click="changeLang('en-US')">English</li>
                </ul>
              </div>
            </div>
          </template>
          <template slot="title">
            <div class="invite-view-form-title">{{ $t('title.informEntry') }}</div>
          </template>
          <template slot="cascader">
            <van-field :class="show?'cascader-up':'cascader'"
                       v-model="fieldValue"
                       is-link
                       readonly
                       :placeholder="$t('placeholder.positionSelect')"
                       @click="show = true" />
          </template>
        </iot-form>
      </div>
      <div class="invite-view-form-background"></div>
    </template>
    <template v-else-if="agreementType">
      <div class="agreement">
        <div class="title">
          <span>{{agreementCenter.title}}</span>
        </div>
        <div class="text">
          <span style="white-space: pre-line;">{{agreementCenter.text}}</span>
        </div>
        <div class="returnPage">
          <span @click="agreementType = false">{{ $t('return') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="invite-view-form"
           :class="{'invite-view-form-zh': locale === 'zh-CN' || locale === 'zh-cn'}">
        <iot-form v-if="formVisible"
                  :form-label-position="'top'"
                  :form-items="formItems"
                  :form-rules="formRules"
                  :form-data="formData"
                  :btn-opera="formData.type === 5?btnOpera3:btnOpera1"
                  @btn-click="btnEvent">
          <div slot="top"
               class="form-top"></div>
          <template slot="return">
            <div class="incite-view-form-return-btn">
              <!-- <img :src="returnBtn"
                   alt=""
                   @click="returnClick"> -->
            </div>
          </template>
          <template slot="logo">
            <div class="incite-view-form-iogo">
              <img :src="companyLogo != ''?companyLogo : imgLogo"
                   alt="" />
              <div class="language"
                   :class="langClick?'show':''"
                   @click="langClick = !langClick">
                <img style="width:20px;"
                     :src="languageImg"
                     alt="" />
                <span>{{language}}</span>
                <i class="van-icon van-icon-arrow " />
                <ul class="selete">
                  <li @click="changeLang('zh-CN')">中文</li>
                  <li @click="changeLang('en-US')">English</li>
                </ul>
              </div>
            </div>
          </template>
          <template slot="title">
            <div class="invite-view-form-title">{{ $t('title.informEntry') }}</div>
          </template>
          <template slot="enableControl">
            <template v-for="item in formData.additionalInfoList">
              <div :key="item.id">
                <div class="iot-form-item-label">{{ item.zhName && item.enName ?($route.query.lang === 'zh-CN' ? $t(item.zhName) : $t(item.enName)):$t(item.name) }}</div>
                <!-- 文本-仅可输入数字 -->
                <template v-if="item.type == 1">
                  <van-field v-model="item.additionalContent"
                             :placeholder="$t('placeholder.visitorNameInput')"
                             :rules="[{ validator: numberCode, message: $t('rules.content') }]" />
                </template>
                <!-- 上传图片 -->
                <template v-if="item.type == 2">
                  <van-uploader v-model="fileList[item.id]"
                                :name="item.id"
                                :max-count="1"
                                preview-size="144px"
                                :after-read="afterRead"
                                :before-delete="beforeDelete">
                  </van-uploader>
                </template>
                <!-- 身份证 -->
                <template v-if="item.type == 3">
                  <van-field v-model="item.additionalContent"
                             :placeholder="$t('placeholder.visitorNameInput')"
                             :rules="[{ validator: checkCode, message: $t('rules.card') }]" />
                </template>
              </div>
            </template>
          </template>
          <template slot="privacy">
            <div class="privacy-box">
              <van-checkbox v-model="privacy"
                            shape="round">
                {{$t('title.privacy1')}}
              </van-checkbox>
              <a class="privacy"
                 @click="agreement">
                {{$t('title.privacy2')}}
              </a>
            </div>
          </template>
        </iot-form>
      </div>
      <div class="invite-view-form-background"></div>
    </template>
    <van-popup v-model="show"
               round
               :style="{ height: '50%'}"
               position="bottom">
      <van-cascader v-model="cascaderValue"
                    :title="$t('placeholder.positionSelect')"
                    :options="localItems[4].selectConfig.options"
                    active-color="#0073e6"
                    :field-names="{
                        text: 'locationName',
                        value: 'id',
                        children: 'childList',
                      }"
                    @close="show = false"
                    @finish="onFinish" />
    </van-popup>
  </div>
</template>

<script>
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import dayjs from "dayjs";
import { getRequest } from "@/utils/common"
import SuccessTip from "./components/success-tip"
import { upload, GetLocationTree, GetAgreement } from "@/api/guider"
import { Toast } from 'vant'
document.title = 'Vistiors'
import {
  GetCauseList,
  RecetionistList,
  VisitorAdd,
  VisitorSetting,
  GetVisitor,
  GetLogo,
} from "@/api/guider";
export default {
  components: {
    SuccessTip,
  },
  data () {
    return {
      requestWait: false,
      formVisible: false,
      formVisibleLocal: false,
      applySuccess: false,
      enableControl: false,
      noticeTimeStamp: null,
      noticeTime: '',
      privacy: 0,
      imgLogo: require("./components/image/logo.png"),
      returnBtn: require("./components/image/return.svg"),
      languageImg: require("./components/image/language.svg"),
      companyLogo: '',
      code: this.$route.params.id,
      locale: navigator.language || navigator.browserLanguage || '',
      fileList: {},
      language: this.$route.query.lang === 'zh-CN' ? 'Language' : '语言切换',
      show: false,
      fieldValue: '',
      cascaderValue: '',
      options: [],
      langClick: false,
      agreementType: false,
      agreementCenter: {
        title: '',
        text: '',
      },
      formData: {
        code: this.$route.params.id,
        type: Number(this.$route.params.type),
        lang: this.$route.params.lang,
        name: "",
        phoneNumber: "",
        email: "",
        visitCauseId: "",
        visitCause: "",
        visitCompanyName: "",
        visitTime: "",
        receptionistId: "",
        receptionistName: "",
        meetingDepartmentId: "",
        mettingDepartment: "",
        estimatedVisitTimeText: new Date().getTime(),
        additionalInfoList: [],
        locationId: '',
        fieldValue: '',
      },
      formItems: [
        {
          type: "slot",
          slot: "top",
          hidden: true,
        }, // 0
        {
          type: "slot",
          slot: "return"
        },
        {
          type: 'slot',
          slot: 'logo'
        },
        {
          type: 'slot',
          slot: 'title'
        },
        {
          label: "label.visitorName",
          placeholder: 'placeholder.visitorPhone',
          prop: "name",
          readonly: false,
          selectConfig: {
            maxlength: "20",
          },
          readonly: Number(this.$route.params.type) === 4,
          disableClass: Number(this.$route.params.type) === 4 ? 'disableClass' : ''
        },
        {
          label: "label.visitorPhone",
          inputType: "tel",
          placeholder: 'placeholder.visitorPhoneInput',
          prop: "phoneNumber",
          hidden: true,
          selectConfig: {
            maxlength: "11",
          },
          readonly: Number(this.$route.params.type) === 1 || Number(this.$route.params.type) === 4,
          disableClass: Number(this.$route.params.type) === 4 ? 'disableClass' : ''
        },
        {
          label: "label.visitorEmail",
          placeholder: 'placeholder.visitorEmailInput',
          prop: "email",
          hidden: true,
          readonly: Number(this.$route.params.type) === 4,
          disableClass: Number(this.$route.params.type) === 4 ? 'disableClass' : ''
        },
        {
          label: "label.visitorCompany",
          placeholder: "placeholder.companyInput",
          prop: "visitCompanyName",
          readonly: Number(this.$route.params.type) === 3 || Number(this.$route.params.type) === 4,
          hidden: false,
          disableClass: Number(this.$route.params.type) === 3 || Number(this.$route.params.type) === 4 ? 'disableClass' : ''
        },  // 5
        {
          type: "picker",
          label: "label.visitPurpose",
          placeholder: 'placeholder.visitPurpose',
          prop: "visitCause",
          clickable: true,
          isLink: true,
          selectConfig: {
            labelName: this.$route.query.lang === 'zh-CN' ? 'zhCauseName' : 'enCauseName',
            valName: "id",
            valueChangeName: "causeChange",
            options: [],
          },
          readonly: Number(this.$route.params.type) === 3 || Number(this.$route.params.type) === 4,
          disableClass: Number(this.$route.params.type) === 3 || Number(this.$route.params.type) === 4 ? 'disableClass purpose-disable' : ''
        },
        {
          type: "time",
          label: "label.visitTime",
          placeholder: 'placeholder.visitTime',
          prop: "estimatedVisitTimeText",
          clickable: true,
          selectConfig: {
            pickerType: "datetime",
            valueFormat: "YYYY-MM-DD HH:mm",
            minDate: new Date(),
            maxDate: new Date(2025, 11, 31, 23),
          },
          readonly: (Number(this.$route.params.type) !== 2 || Number(this.$route.params.type) === 4) && Number(this.$route.params.type) !== 5,
          disableClass: Number(this.$route.params.type) !== 2 && Number(this.$route.params.type) !== 5 ? 'disableClass' : '',
          hidden: false,
        },
        {
          label: 'label.receptionistPhone',
          placeholder: 'placeholder.hostPhone',
          prop: "receptionistName",
          selectConfig: {
            labelName: "name",
            valName: "id",
            valueChangeName: "receptionistChange",
            options: [],
          },
          readonly: Number(this.$route.params.type) === 5 ? (getRequest(location.href).receptionistName ? true : false) : Number(this.$route.params.type) !== 2,
          disableClass: Number(this.$route.params.type) === 5 ? (getRequest(location.href).receptionistName ? 'disableClass' : '') : (Number(this.$route.params.type) !== 2 ? 'disableClass' : '')
        },
        {
          label: "label.visitLocation",
          placeholder: 'placeholder.positionSelect',
          prop: "mettingDepartment",
          readonly: Number(this.$route.params.type) !== 2 || Number(this.$route.params.type) === 4,
          disableClass: Number(this.$route.params.type) !== 2 ? 'disableClass' : '',
          hidden: true,
        },
        {
          label: 'label.visitLocation',
          placeholder: '',
          prop: "fieldValue",
          readonly: true,
          disableClass: 'disableClass',
          hidden: Number(this.$route.params.type) !== 5,
        },
        {
          type: "slot",
          slot: "top",
          hidden: true
        },
        // 此处应该是动态的
        {
          type: 'slot',
          slot: 'enableControl',
          prop: 'locationControlTypes',
          editorProp: 'locationControlTypes'
        },
        // end
        // {
        //   type: 'slot',
        //   slot: 'privacy',
        //   hidden: Number(this.$route.params.type) !== 2 && Number(this.$route.params.type) !== 5,
        //   editorProp: 'privacy',
        //   style: {
        //     padding: '16px 0 6px'
        //   }
        // }
      ],
      btnOpera1: [
        {
          type: 'slot',
          slot: 'privacy',
          hidden: Number(this.$route.params.type) == 4 || (Number(this.$route.params.type) !== 2 && Number(this.$route.params.type) !== 5),
          editorProp: 'privacy',
          style: {
            padding: '16px 0 6px'
          }
        },
        {
          block: true,
          size: "normal",
          type: "info",
          label: "button.submit",
          nativeType: "submit",
          round: true,
        },
      ],
      formRules: {
        name: [
          {
            required: true,
            message: 'placeholder.visitorPhone'
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: 'placeholder.visitorPhoneInput'
          },
          {
            message: 'placeholder.phoneWrong',
            pattern: /^1(3|4|5|6|7|8|9)\d{9}$/
          }
        ],
        email: [
          {
            required: true,
            message: 'placeholder.visitorEmailInput'
          },
          {
            message: 'placeholder.emailWrong',
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
          },
        ],
        visitCause: [
          {
            required: true,
            message: 'placeholder.visitPurpose',
          },
        ],
        visitCompanyName: [
          {
            required: true,
            message: 'placeholder.companyInput',
          },
        ],
        visitTime: [
          {
            required: true,
            message: 'placeholder.visitTime',
          },
        ],
        receptionistName: [
          {
            required: true,
            // message: Number(this.$route.params.type) == 2 ? 'placeholder.hostPhone' : 'placeholder.hostName',
            message: 'placeholder.hostPhone',
          },
        ],
        estimatedVisitTimeText: [
          {
            required: true,
            message: 'placeholder.visitTime',
          },
        ]
      },
      viewSwitch: true,
      localData: {
        locationId: '',
      },
      localItems: [
        {
          type: "slot",
          slot: "top",
        }, // 0
        {
          type: "slot",
          slot: "return"
        },
        {
          type: 'slot',
          slot: 'logo'
        },
        {
          type: 'slot',
          slot: 'title'
        },
        // {
        //   type: "cascader",
        //   label: 'label.visitLocation',
        //   placeholder: 'placeholder.positionSelect',
        //   prop: "locationId",
        //   clickable: true,
        //   selectConfig: {
        //     title: 'label.visitLocation',
        //     labelName: "locationName",
        //     valName: "id",
        //     childName: "childList",
        //     valueChangeName: "localChange",
        //     options: [],
        //   },
        // },
        {
          type: 'slot',
          slot: 'cascader',
          label: 'label.visitLocation',
          selectConfig: {
            title: 'label.visitLocation',
            labelName: "locationName",
            valName: "id",
            childName: "childList",
            valueChangeName: "localChange",
            options: []
          },
        }
      ],
      btnOpera2: [
        {
          block: true,
          size: "normal",
          type: "info",
          label: 'button.next-step',
          nativeType: "submit",
          round: true,
        },
      ],
      btnOpera3: [
        {
          block: true,
          size: "normal",
          type: "default",
          label: 'return',
          eventName: 'returnClick',
          nativeType: "button",
          round: true,
        },
        {
          block: true,
          size: "normal",
          type: "info",
          label: 'button.submit',
          nativeType: "submit",
          round: true,
        },
      ],
      localRules: {
        locationId: [
          {
            required: true,
            message: 'placeholder.positionSelect',
          },
        ],
      }
    };
  },
  computed: {
    estimatedVisitTimeText () {
      return this.formData.estimatedVisitTimeText;
    },
    visitTime () {
      return this.formData.visitTime;
    },
    visitPrivacy () {
      return this.privacy
    },
    showNotice () {
      return (this.enableControl && this.noticeTimeStamp && dayjs().isBefore(dayjs(this.noticeTimeStamp), 'day'))
    }
  },
  watch: {
    estimatedVisitTimeText (val) {
      this.formData.visitTime = new Date(dayjs(val)).getTime();
    },
    visitTime (val) {
      this.formData.estimatedVisitTimeText = dayjs(new Date(dayjs(val))).format(
        "YYYY-MM-DD HH:mm"
      );
    },
    visitPrivacy (val) {
      if (val == 1) {
        this.btnOpera1[1].disabled = false
      } else {
        this.btnOpera1[1].disabled = true
      }
    }
  },
  mounted () {
    // 初始化
    this.init();
    setTimeout(() => {
      this.formItems[13].hidden = true;
    }, 1000);
    //组件中英文切换
    this.$route.query.lang === 'zh-CN' ? '' : Locale.use('en-US', enUS)
  },
  methods: {
    /**
     * @description 初始化
     */
    init () {
      //获取联系方式类型
      if (this.formData.type != 5) this.getVisitorSetting();
      // 获取到访事由 GetCauseList
      this.getCauseOptions();
      // 获取信息
      if (this.formData.type !== 2 && this.formData.type !== 5) {
        this.getInfo();
      } else {
        let a = new Date().getMinutes();
        let b = a - (a % 10) + 10;
        let d = new Date().setMinutes(b);
        this.formData.visitTime = new Date(dayjs(d)).getTime();
        //  this.formVisible = true;
      };
      //获取公司logo
      this.getLogo();
      this.langChange();
      if (this.formData.type == 5) this.getLoaction()
    },
    /**
     * @description 位置树选择
     */
    onFinish ({ selectedOptions, tabIndex }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.locationName).join('/');
      this.formData.fieldValue = this.fieldValue
      this.formData.locationId = selectedOptions[tabIndex].id
      this.localData.locationId = selectedOptions[tabIndex].id
    },
    /**
     * @description 获取位置树
     */
    getLoaction () {
      GetLocationTree({
        companyId: this.code,
        level: 6
      }).then((res) => {
        this.localItems[4].selectConfig.options = JSON.parse(JSON.stringify(res[0].childList))
        this.formVisibleLocal = true
      })
    },
    /**
     * @description 获取隐私协议
     */
    agreement () {
      GetAgreement({
        code: this.code,
        type: this.formData.type,
      }).then((res) => {
        this.agreementType = true
        this.agreementCenter.title = res.title
        this.agreementCenter.text = res.text
      })
    },
    /**
     * @description 切换语言
     */
    langChange () {
      this.$i18n.locale = getRequest(location.href).lang
    },
    /**
     * @description 按钮事件
     * @param { String } eventName 事件名称
     */
    btnEvent (eventName, info) {
      this["_" + eventName](info);
    },
    /**
     * @description 获取到访事由列表
     */
    getCauseOptions () {
      GetCauseList({
        code: this.code,
        type: this.formData.type,
      }).then((res) => {
        res.forEach(x => {
          if (!x.zhCauseName) x.zhCauseName = x.causeName
          if (!x.enCauseName) x.enCauseName = x.causeName
        })
        this.formItems[8].selectConfig.options = res;
      });
    },
    /**
     * @description 获取到接待人列表
     */
    getRecetionist () {
      RecetionistList({
        code: this.code,
        type: this.formData.type,
      }).then((res) => {
        this.formItems[10].selectConfig.options = res;
      });
    },
    /**
     * @description 获取访客联系方式设置
     */
    getVisitorSetting () {
      let param = {
        code: this.code,
        type: this.formData.type,
      }
      if (this.formData.type == 5) {
        param = {
          code: this.formData.locationId,
          type: 5
        }
      }
      VisitorSetting(param).then((res) => {
        if (res.enableTemporaryVisitor == 0) {
          Toast(this.$t('NoVision'))
          this.viewSwitch = true
          return
        } else {
          this.viewSwitch = false
        }
        //判断访客的联系方式类型
        if (res.visitorContact === 1) {
          this.formItems[5].hidden = false;
          this.formItems[6].hidden = true;
        } else {
          this.formItems[5].hidden = true;
          this.formItems[6].hidden = false;
        }
        //判断是否允许访客输入所属公司
        if (res.enableVisitorCompany === 1) {
          this.formItems[7].hidden = false;
        } else {
          this.formItems[7].hidden = true;
        }
        //判断是否允许访客选择邀约时间
        if (res.enableChooseTime === 1) {
          this.formItems[9].hidden = false;
        } else {
          this.formItems[9].hidden = true;
        }
        // 隐私设置
        if (this.formData.type == 2 || this.formData.type == 5) {
          if (res.enablePrivacy != 1) {
            let privacy = this.btnOpera1.find(item => item.editorProp == 'privacy')
            privacy.hidden = true
          } else {
            if (this.privacy == 0) {
              this.btnOpera1[1].disabled = true
            }
          }
        }
        if (this.formData.type == 2 || this.formData.type == 5) {
          if (res.additionalInfoList != null) {
            this.formData.additionalInfoList = res.additionalInfoList
            for (let i = 0; i < res.additionalInfoList.length; i++) {
              let item = res.additionalInfoList[i]
              if (item.type == 2) {
                this.$set(this.fileList, item.id, [])
              }
            }
          }
          // 管控数据
          if (res.enableControl == 0) {
            let locationControlTypes = this.formItems.find(item => item.editorProp == 'locationControlTypes')
            locationControlTypes.hidden = true
          }
          this.enableControl = res.enableControl == 1 ? true : false
          this.formVisible = true;
        }
      });
    },
    /**
     * @description 获取信息
     */
    getInfo () {
      GetVisitor({
        code: this.code,
        type: this.formData.type,
      }).then((res) => {
        if (this.formData.type != 5) {//为5时，是专属访客链接，需要用户手动填写数据
          for (var i in this.formData) {
            if (res[i] || res[i] === 0) {
              this.formData[i] = res[i]
            }
          }
        }
        if (res.additionalInfoList != null) {
          for (let i = 0; i < res.additionalInfoList.length; i++) {
            let item = res.additionalInfoList[i]
            if (item.type == 2) {
              this.$set(this.fileList, item.id, [])
            }
          }
        }

        // 管控数据
        if (res.enableControl == 0) {
          let locationControlTypes = this.formItems.find(item => item.editorProp == 'locationControlTypes')
          locationControlTypes.hidden = true
        }
        if (res.noticeTimeStamp) {
          this.noticeTimeStamp = res.noticeTimeStamp
          this.noticeTime = dayjs(res.noticeTimeStamp).format('YYYY-MM-DD')
        }
        this.enableControl = res.enableControl == 1 ? true : false
        this.formVisible = true;
      });
    },
    /**
     * @description 获取公司logo
     */
    getLogo () {
      GetLogo({
        code: this.code,
        type: this.formData.type
      }).then(res => {
        if (res) this.companyLogo = res
      })
    },
    /**
     * @description 切换中英文
     */
    changeLang (val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      console.log(query.lang, val)
      if (query.lang !== val) {
        query.lang = val
        this.$router.push({ path: this.$route.path, query })
        location.reload()
      }
    },
    /**
     * @description 返回上一页
     */
    _returnClick () {
      this.viewSwitch = true
      this.formData = {
        code: this.$route.params.id,
        type: Number(this.$route.params.type),
        lang: this.$route.params.lang,
        name: "",
        phoneNumber: "",
        email: "",
        visitCauseId: "",
        visitCause: "",
        visitCompanyName: "",
        visitTime: "",
        receptionistId: "",
        receptionistName: "",
        meetingDepartmentId: "",
        mettingDepartment: "",
        estimatedVisitTimeText: new Date().getTime(),
        additionalInfoList: [],
        locationId: '',
      }
      this.fieldValue = ''
      this.cascaderValue = ''
    },
    // 组件方法
    /**
     * @description 表单提交
     * 
     */
    _onSubmit () {
      if (this.viewSwitch) {
        if (!this.formData.locationId) return Toast(this.$t('placeholder.positionSelect'))
        this.getVisitorSetting()
        if (getRequest(location.href).receptionistName) this.formData.receptionistName = getRequest(location.href).receptionistName
      } else {
        if (this.formData.additionalInfoList) {
          let key
          for (let i = 0; i < this.formData.additionalInfoList.length; i++) {
            key = this.formData.additionalInfoList[i]
            if (key.type == 2) {
              if (!key.additionalContent || key.additionalContent == '') {
                Toast.fail(this.$t('rules.upload') + key.name);
                return
              }
            }
          }
        }
        if (this.requestWait) return
        this.requestWait = true
        this.$toast.loading({
          duration: 0,
          message: 'Loading...',
          forbidClick: true,
        })
        VisitorAdd(this.formData).then((res) => {
          this.formData.mettingDepartment = res
          this.applySuccess = true;
          this.$toast.clear()
        }).finally(() => {
          this.requestWait = false;
        })
      }
    },
    /**
     * @description 到访事由信息改变
     */
    _causeChange (info) {
      this.formData.visitCauseId = info.id;
    },
    /**
     * @description 接待人信息改变
     */
    _receptionistChange (info) {
      this.formData.receptionistId = info.id;

      this.formData.meetingDepartmentId = info.groupId;
      this.formData.mettingDepartment = info.groupName;

      this.formItems[11].hidden = true;
      setTimeout(() => {
        this.formItems[11].hidden = false;
      }, 50);
    },
    _uploadChange (val) {
      let file = val[0]
    },
    afterRead (file, detail) {
      file.status = 'uploading'
      file.message = this.$t('Uploading')
      let imgFile = new FormData();
      imgFile.append("file", file.file)
      upload(imgFile).then(res => {
        file.status = 'done'
        // 设置additionalContent值
        let find = this.formData.additionalInfoList.find(item => item.id == detail.name)
        find.additionalContent = res.pathOfImage
      })
    },
    beforeDelete (file, detail) {
      let find = this.formData.additionalInfoList.find(item => item.id == detail.name)
      find.additionalContent = ''
      return true
    },
    numberCode (val) {
      return val.length > 0 && val.length < 200
    },
    // 身份证正则
    checkCode (val) {
      return /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(val)
    }
  },
};
</script>

<style lang="scss">
.invite-view {
  position: absolute;
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  .agreement {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px 15px 40px 15px;
    .title {
      margin-bottom: 30px;
      & > span {
        font-weight: 700;
        font-size: 32px;
      }
    }
    .text {
      & > span {
        font-size: 16px;
        line-height: 24px;
      }
      margin-bottom: 84px;
    }
    .returnPage {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 84px;
      padding-bottom: 34px;
      background-color: #ffffff;
      box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.16);
      & > span {
        font-size: 15px;
        line-height: 20px;
        color: #0073e6;
        font-weight: 500;
      }
    }
  }
  .incite-view-form-return-btn {
    height: 1px;
  }
  .incite-view-form-iogo {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 40px;
    background-size: 154px;
    background-color: #fff;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > img {
      height: 100%;
      display: block;
      float: left;
    }
    .language {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > img {
        margin-right: 4px;
      }
      & > i {
        transform: rotate(90deg);
        margin-left: 8px;
      }
      .selete {
        display: none;
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        transform: translate(-50%, 0);
        width: 128px;
        background-color: #ffffff;
        box-shadow: 0 2px 12px rgba(50, 50, 51, 0.12);
        border-radius: 8px;
        &::before {
          content: "";
          position: absolute;
          top: -5px;
          right: 50%;
          transform: translateX(50%);
          border-color: transparent;
          border-bottom-color: #fff;
          border-width: 6px;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-top-width: 0;
          z-index: 10;
        }
        & > li {
          text-align: center;
          padding: 12px 0;
          &:first-child {
            position: relative;
            &::before {
              left: 50%;
              bottom: 0;
              transform: translate(-50%, 0);
              content: "";
              position: absolute;
              width: 96px;
              height: 1px;
              background-color: #ebedf0;
            }
          }
        }
      }
    }
    .show {
      .selete {
        display: block;
      }
    }
  }
  .invite-view-form-title {
    font-weight: 600;
    font-size: 22px;
    color: #3e3b3b;
    text-align: left;
    position: relative;
    margin-bottom: 8px;
  }
  .invite-view-form {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .iot-form-main {
      padding: 0 20px;
      .iot-form-item-label {
        color: #4d4d4d;
        font-size: 15px;
        line-height: 20px;
        padding: 16px 0 8px;
      }
      .van-cell {
        border-radius: 4px;
        border: 1px solid rgba(#dedede, 1);
        padding: 0 16px;
        line-height: 48px;
        align-items: center;
        .van-field__control {
          font-size: 15px;
          font-weight: 400;
        }
      }
      .disableClass {
        background-color: #fafafa;
        border: 1px solid rgba(#979797, 0.2);
        input {
          color: #cccccc;
        }
      }
      .cascader {
        .van-icon {
          transform: rotate(90deg);
          transition: 500ms;
        }
      }
      .cascader-up {
        .van-icon {
          transform: rotate(270deg);
          transition: 500ms;
        }
      }
    }
    .van-button {
      height: 52px;
      font-size: 15px;
      background: url("./components/image/button-background.png") no-repeat
          center,
        linear-gradient(112deg, #4e90ff 0%, #2658fe 100%);
      box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.14);
    }
    .van-button--default {
      color: #0073e6;
      border: 1px solid #0073e6;
      background: none;
    }
    .van-button__content {
      background-size: 52px;
    }
    .form-top {
      height: 100px;
    }
    &.invite-view-form-zh {
      .van-button__content {
        background-image: none;
      }
    }
  }
  .invite-view-form-background {
    top: 40%;
    position: absolute;
    width: 100%;
    height: 60%;
    background: url("../../styles/images/invite/visitor-background.png")
      no-repeat;
    background-size: 100%;
    z-index: -1;
  }
  .privacy-box {
    display: flex;
    justify-content: flex-start;
    padding: 8px 0 0;
    margin: 0 0 0 20px;
  }
  .privacy {
    color: #0095ea;
  }
  .iot-form-item-label {
    position: relative;
    color: #646566;
    font-size: 18px;
    line-height: 0.64rem;
    padding: 10px 15px;
  }
  .van-image__img,
  .van-uploader__mask {
    border-radius: 6px;
    border: 1px solid rgba(#979797, 0.38);
  }
  .van-uploader__upload,
  .van-uploader__preview-image {
    border-radius: 6px;
    border: 1px solid rgba(#979797, 0.38);
  }
  .van-uploader__preview-image {
    border: none;
  }
  .van-uploader__preview-delete {
    width: 20px;
    height: 20px;
    border-radius: 0;
    border-top-right-radius: 6px;
  }
  .van-uploader__preview-delete-icon {
    top: -6px;
    right: -6px;
    font-size: 32px;
  }
  .van-field--error .van-field__control::placeholder {
    color: #c8c9cc;
  }
  .notice-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: #8c9196;
    font-size: 15px;
    img {
      margin-bottom: 16px;
    }
  }
}
.van-popup {
  border-radius: 12px 12px 0 0;
  .van-cascader {
    .van-cascader__header {
      height: 70px;
      &::before {
        content: "";
        width: 22px;
        height: 22px;
      }
      .van-cascader__title {
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
      }
      .van-cascader__close-icon {
        color: #6d7175;
        height: 24px;
        width: 24px;
        line-height: 24px;
        &::before {
          content: url(./components/image/close.svg);
        }
      }
    }
    .van-tabs {
      .van-tabs__wrap {
        height: 20px;
        .van-tabs__nav {
          padding: 0 0 0 8px;
          .van-tab {
            padding: 0 8px;
          }
          .van-tab__text {
            font-size: 15px;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
      .van-tabs__content {
        .van-cascader__options {
          padding-top: 11px;
          .van-cascader__option {
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            padding: 11px 16px;
          }
        }
      }
    }
    .van-cascader__options {
      .van-icon {
        height: 24px;
        width: 24px;
        line-height: 24px;
        &::before {
          content: url(./components/image/check.svg);
        }
      }
    }
  }
  .van-picker {
    .van-picker__toolbar {
      height: 70px;
      & > button {
        font-size: 15px;
      }
      .van-picker__cancel {
        color: #6d7175;
      }
      .van-picker__confirm {
        color: #0073e6;
      }
    }
  }
}
</style>
