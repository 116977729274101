import request from '@/utils/request'

// 获得访客签到二维码
export function GetQr(data) {
    return request({
        url: '/h5/getQr',
        method: 'get',
        params: data
    })
}

// 访客添加
export function VisitorAdd(data) {
    return request({
        url: '/h5/save',
        method: 'post',
        data
    })
}

// 获取到访事由列表
export function GetCauseList(data) {
    return request({
        url: '/h5/queryCauseList',
        method: 'get',
        params: data
    })
}

// 获取接待人列表
export function RecetionistList(data) {
    return request({
        url: '/h5/queryRecetionistList',
        method: 'get',
        params: data
    })
}

//获取部门列表
export function GroupList(data) {
    return request({
        url: '/h5/queryGroupList',
        method: 'get',
        params: data
    })
}

//获取访客联系方式类型
export function VisitorSetting(data) {
    return request({
        url: '/h5/getVisitorSetting',
        method: 'get',
        params: data
    })
}

//获取访客联系方式类型
export function GetVisitor(data) {
    return request({
        url: '/h5/getVisitor',
        method: 'get',
        params: data
    })
}

//获取公司logo
export function GetLogo(data) {
    return request({
        url: '/h5/getCompanyLogo',
        method: 'get',
        params: data
    })
}


export function upload (data) {
    return request({
        headers: {
            "Content-Type": "multipart/form-data"
        },
        transformRequest: [data => data],
        url: '/h5/upload',
        method: 'post',
        data
    })
}

//获取位置树列表
export function GetLocationTree (data) {
  return request({
    url: '/h5/location/getLocationTree',
    method: 'get',
    params: data
  })
}

//获取隐私协议
export function GetAgreement (data) {
  return request({
    url: '/h5/privacy-agreement-settings/get',
    method: 'get',
    params: data
  })
}